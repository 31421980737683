var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{attrs:{"align-center":""}},[_c('v-flex',{attrs:{"xs10":""}},[_c('h3',{staticClass:"secondary--text"},[_vm._v("Conta Usuário")])]),_c('v-spacer'),_c('v-flex',{attrs:{"xs12":"","md4":"","text-xs-right":""}},[_c('v-text-field',{attrs:{"label":"Pesquisar","color":"secondary","append-icon":"search"},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}})],1)],1),_c('v-layout',{attrs:{"justify-space-around":"","row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"default-border",attrs:{"flat":"","text":""}},[_c('v-data-table',{staticClass:"elevation-1 table-motoristas",attrs:{"headers":_vm.headers,"items":_vm.listaModelo,"search":_vm.filtro,"sort-by":['dataInclusao'],"sort-desc":[true],"items-per-page":20,"footer-props":{
                itemsPerPageAllText: 'Todos',
                itemsPerPageText: 'Itens por página',
            }},scopedSlots:_vm._u([{key:"item.codigoWallet",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.codigoWallet ? item.codigoWallet : "Vazio")+" ")]}},{key:"item.contaChavePixVip",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.contaChavePixVip ? item.contaChavePixVip.chavePix : "Sem vinculo")+" ")]}},{key:"item.limiteDiarioBrl",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.customFormatComputed.formatarReais(item.limiteDiarioBrl))+" ")]}},{key:"item.email",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.email ? item.email : "Vazio")+" ")]}},{key:"item.empresa",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.empresa ? item.empresa.apelido : "Sem empresa")+" ")]}},{key:"item.empresaCodigo",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.empresaCodigo ? item.empresaCodigo.codigo : "Sem código")+" ")]}},{key:"item.dataInclusao",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.customFormatComputed.toLocaleDateTimeBrPtString(item.dataInclusao))+" ")]}},{key:"item.controle",fn:function(ref){
            var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('v-tooltip',{attrs:{"color":"secondary","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('td',[_c('v-btn',_vm._g({staticClass:"ma-2 px-1 icon-edit",attrs:{"small":"","depressed":"","fab":"","color":"secondary"},on:{"click":function($event){_vm.go('Pedidos', {
                id:
                    _vm.cipherComputed.encrypt(item.idContaUsuario)
            })}}},on),[_c('v-icon',{staticStyle:{"font-size":"16px"}},[_vm._v("mdi-cart-arrow-down")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Ver pedidos da conta "+_vm._s(item.codigoConta))])]),_c('v-tooltip',{attrs:{"color":"secondary","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('td',[_c('v-btn',_vm._g({staticClass:"ma-2 px-1 icon-edit",attrs:{"small":"","depressed":"","fab":"","color":"secondary","disabled":item.empresaCodigo == null || (item.empresaCodigo != null && item.contaChavePixVip != null)},on:{"click":function($event){return _vm.criaVip(item)}}},on),[_c('v-icon',{staticStyle:{"font-size":"16px"}},[_vm._v("mdi-account-check")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Adicionar VIP "+_vm._s(item.codigoConta))])]),_c('v-tooltip',{attrs:{"color":"secondary","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('td',[_c('v-btn',_vm._g({staticClass:"ma-2 px-1 icon-edit",attrs:{"small":"","depressed":"","fab":"","color":"secondary"},on:{"click":function($event){_vm.go('CadastroContaUsuarios', {
                id:
                    _vm.cipherComputed.encrypt(item.idContaUsuario)
            })}}},on),[_c('v-icon',{staticStyle:{"font-size":"16px"}},[_vm._v("edit")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Editar "+_vm._s(item.codigoConta))])])],1)]}}],null,true)})],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":3200,"color":("" + (_vm.snackbarModel.sucesso ? 'success' : 'error')),"bottom":"","multi-line":""},model:{value:(_vm.snackbarModel.mostrar),callback:function ($$v) {_vm.$set(_vm.snackbarModel, "mostrar", $$v)},expression:"snackbarModel.mostrar"}},[_vm._v(" "+_vm._s(_vm.snackbarModel.text)+" "),_c('v-btn',{attrs:{"dark":"","text":""},nativeOn:{"click":function($event){_vm.snackbarModel.mostrar = false}}},[_vm._v("Fechar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }