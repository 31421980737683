<template>
    <v-container>
        <v-layout align-center>
            <v-flex xs10>
                <h3 class="secondary--text">Pedido Saques</h3>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 md4 text-xs-right>
                <v-text-field label="Pesquisar" v-model="filtro" color="secondary" append-icon="search"></v-text-field>
            </v-flex>
        </v-layout>
        <v-layout justify-space-around row wrap>
            <v-flex xs12>
                <v-card class="default-border" flat text>
                    <v-data-table :headers="headers" :items="listaModelo" class="elevation-1 table-motoristas"
                        :search="filtro" :sort-by="['dataInclusao']" :sort-desc="[true]" :items-per-page="20"
                        :footer-props="{
                    itemsPerPageAllText: 'Todos',
                    itemsPerPageText: 'Itens por página',
                }">
                        <template v-slot:[`item.numeroDocumento`]="{ item }">
                            {{ customFormatComputed.formatCPFCNPJNumber(item.numeroDocumento) }}
                        </template>
                        <template v-slot:[`item.valorBRL`]="{ item }">
                            {{ customFormatComputed.formatarReais(item.valorBRL) }}
                        </template>
                        <template v-slot:[`item.contaUsuario.empresaCodigo`]="{ item }">
                            {{ item.contaUsuario.empresaCodigo ? item.contaUsuario.empresaCodigo.codigo : "Sem Código" }}
                        </template>
                        <template v-slot:[`item.empresa`]="{ item }">
                            {{ item.empresa ? item.empresa.apelido : "Sem Empresa" }}
                        </template>
                        <template v-slot:[`item.dataInclusao`]="{ item }">
                            {{
                    customFormatComputed.toLocaleDateTimeBrPtString(item.dataInclusao)
                }}
                        </template>
                        <template v-slot:[`item.controle`]="{ item }">
                            <v-layout justify-center>
                                <v-tooltip color="secondary" bottom>
                                    <template v-slot:activator="{ on }">
                                        <td>
                                            <v-btn small depressed fab v-on="on" color="secondary"
                                                class="ma-2 px-1 icon-edit" @click="abrirModalStatus(item)">
                                                <v-icon style="font-size: 16px">mdi-account-details-outline</v-icon>
                                            </v-btn>
                                        </td>
                                    </template>
                                    <span>Ver detalhes do pedido {{ item.idPedidoSaque }}</span>
                                </v-tooltip>
                            </v-layout>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
        <template>
            <v-dialog v-model="dialogStatus" persistent>
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Pedido de Saque - {{ cadastroModelo.idPedidoSaque }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container grid-list-lg>
                            <v-layout row wrap>
                                <v-flex xs12><v-divider></v-divider></v-flex>
                                <v-flex xs12><b>Wallet</b></v-flex>
                                <v-flex xs12><v-divider></v-divider></v-flex>
                                <v-flex xs12 md4>Código: {{ cadastroModelo.contaUsuario.codigoWallet }}</v-flex>
                                <v-flex xs12 md4>Conta: {{ cadastroModelo.contaUsuario.codigoConta }}</v-flex>
                                <v-flex xs12 md4>Limite: {{ cadastroModelo.contaUsuario.limite }}</v-flex>
                                <v-flex xs12 md4>Empresa: {{ cadastroModelo.contaUsuario.empresa.apelido }}</v-flex>
                                <v-flex xs12 md4>Nome do código: {{ cadastroModelo.contaUsuario.empresa.empresaCodigo ?
                    cadastroModelo.contaUsuario.empresaCodigo.nome : 'Vazio' }}</v-flex>
                                <v-flex xs12 md4>Código: {{ cadastroModelo.contaUsuario.empresaCodigo ?
                    cadastroModelo.contaUsuario.empresaCodigo.codigo : 'Vazio' }}</v-flex>
                                <v-flex xs12 md4>Spread do código: {{ cadastroModelo.contaUsuario.empresaCodigo ?
                    cadastroModelo.contaUsuario.empresaCodigo.spread : 'Vazio' }}</v-flex>
                                <v-flex xs12 md4>Limite do código: {{ cadastroModelo.contaUsuario.empresaCodigo ?
                    cadastroModelo.contaUsuario.empresaCodigo.limite : 'Vazio' }}</v-flex>
                                <v-flex xs12><v-divider></v-divider></v-flex>
                                <v-flex xs12><b>Detalhes do Saque</b></v-flex>
                                <v-flex xs12><v-divider></v-divider></v-flex>
                                <v-flex xs12 md4>Email para QRDINDIN: {{ cadastroModelo.email }}</v-flex>
                                <v-flex xs12 md4>Moeda: {{ cadastroModelo.valorMoeda }}</v-flex>
                                <v-flex xs12 md4>Cotação media moeda: {{ cadastroModelo.cotacaoMoedaMedio }}</v-flex>
                                <v-flex xs12 md4>Spreed medio moeda: {{ cadastroModelo.spreadMedio }}</v-flex>
                                <v-flex xs12 md4>Data Pagamento: {{
                    customFormatComputed.toLocaleDateTimeBrPtString(cadastroModelo.dataPagamento)
                }}</v-flex>
                                <v-flex xs12 md4>Data Inclusão: {{
                        customFormatComputed.toLocaleDateTimeBrPtString(cadastroModelo.dataInclusao) }}</v-flex>
                                <v-flex xs12><v-divider></v-divider></v-flex>
                                <v-flex xs12><b>Histórico do Pedido</b></v-flex>
                                <v-flex xs12><v-divider></v-divider></v-flex>
                                <v-flex xs12>
                                    <v-card class="default-border" flat text>
                                        <v-data-table :headers="headersPedidoSaquePedido"
                                            :items="cadastroModelo.listPedidoSaquePedido"
                                            class="elevation-1 table-motoristas" :search="filtro"
                                            :sort-by="['dataInclusao']" :sort-desc="[true]" :items-per-page="20"
                                            :footer-props="{
                    itemsPerPageAllText: 'Todos',
                    itemsPerPageText: 'Itens por página',
                }">
                                        <template v-slot:[`item.pedido.valorBRL`]="{ item }">
                            {{ customFormatComputed.formatarReais(item.pedido.valorBRL) }}
                        </template>
                                            <template v-slot:[`item.dataInclusao`]="{ item }">
                                                {{
                    customFormatComputed.toLocaleDateTimeBrPtString(item.dataInclusao)
                }}
                                            </template>

                                        </v-data-table>
                                    </v-card>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="closeStatus">
                            Fechar
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </v-dialog>
        </template>
        <v-snackbar :timeout="3200" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`"
            v-model="snackbarModel.mostrar" bottom multi-line>
            {{ snackbarModel.text }}
            <v-btn dark text @click.native="snackbarModel.mostrar = false">Fechar</v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
import cipher from "@/helpers/cipher.js";
import customFormat from "@/helpers/custom-format.js";

export default {
    name: "PedidoSaques",
    data: () => ({
        dialogStatus: false,
        snackbarModel: {
            mostrar: false,
            sucesso: false,
            text: "",
        },
        filtro: "",
        headers: [
            { text: "ID", value: "idPedidoSaque" },
            { text: "Wallet", value: "contaUsuario.codigoWallet" },
            { text: "Código", value: "contaUsuario.empresaCodigo" },
            { text: "BRL", value: "valorBRL" },
            { text: "Tipo Moeda", value: "tipoMoeda.nome" },
            { text: "Moeda", value: "valorMoeda" },
            { text: "Spread Médio", value: "spreadMedio" },
            { text: "Cotação Médio", value: "cotacaoMoedaMedio" },
            { text: "Empresa", value: "empresa" },
            { text: "Data Inclusão", value: "dataInclusao" },
            { text: "", value: "controle", sortable: false },
        ],
        headersPedidoSaquePedido: [
            { text: "Id Pedido", value: "pedido.idPedido" },
            { text: "Tipo Moeda", value: "pedido.tipoMoeda.nome" },
            { text: "BRL", value: "pedido.valorBRL" },
            { text: "Moeda", value: "pedido.valorMoeda" },
            { text: "Data Inclusão", value: "dataInclusao" },
        ],
        cadastroModelo: {
            contaUsuario: {
                codigoWallet: null,
                codigoConta: null,
                limite: 0,
                empresa: {
                    apelido: null,
                },
                empresaCodigo: {
                    nome: null,
                    codigo: null,
                    spread: 0,
                    limite: 0
                },
            },
            tipoMoeda: {
                nome: null
            },
            valorBRL: 0,
            valorMoeda: 0,
            valorBonusMoeda: 0,
            cotacaoMoedaMedio: 0,
            spreadMedio: 0,
            empresa: {
                apelido: null,
            },
            email: null,
            dataPagamento: null,
            dataInclusao: null,
            listPedidoSaquePedido: []
        },
        listaModelo: []
    }),
    mounted: function () {
        this.buscar()
        this.externalSnackbarMessage()
    },
    computed: {
        customFormatComputed: function () {
            return customFormat;
        },
        cipherComputed: function () {
            return cipher;
        }
    },
    methods: {
        abrirModalStatus(item) {
            const self = this;
            self.cadastroModelo = item

            self.dialogStatus = true;
        },
        closeStatus() {
            const self = this;

            self.dialogStatus = false;
        },
        buscar: function () {
            const self = this;
            if (
                this.$route.params.id &&
                Number.isInteger(parseInt(cipher.decrypt(this.$route.params.id)))
            ) {
                self.$store.commit("startLoading");
                self.$http
                    .get(
                        `${process.env.VUE_APP_URL_API}/pedido-saque/busca/` +
                        cipher.decrypt(this.$route.params.id),
                        {
                            headers: {
                                Authorization:
                                    `Bearer ${localStorage.getItem("token")}`,
                            },
                        }
                    )
                    .then(function (response) {
                        self.listaModelo = response.data;
                        self.$store.commit("endLoading");
                    }).catch(function (error) {
                        console.log(error.response.data)
                        self.$store.commit("endLoading")
                    });
            } else {
                self.$store.commit("startLoading");
                self.$http
                    .get(`${process.env.VUE_APP_URL_API}/pedido-saque/busca`, {
                        headers: {
                            Authorization:
                                `Bearer ${localStorage.getItem("token")}`,
                        },
                    })
                    .then(function (response) {
                        self.listaModelo = response.data;
                        self.$store.commit("endLoading");
                    }).catch(function (error) {
                        console.log(error.response.data)
                        self.$store.commit("endLoading")
                    });
            }
        },
        externalSnackbarMessage: function () {
            const self = this;
            if (self.$route.params.snackbarMostrar) {
                self.snackbarModel.sucesso = self.$route.params.snackbarSucesso;
                self.snackbarModel.text = self.$route.params.snackbarText;
                self.snackbarModel.mostrar = self.$route.params.snackbarMostrar;
            }
        },
        go: function (routeName, routeParams) {
            const self = this;
            if (!routeParams) {
                self.$router.push({ name: routeName });
            } else {
                self.$router.push({ name: routeName, params: routeParams });
            }
        }
    }
};
</script>