<template>
  <v-container grid-list-md align-start>
    <v-layout row wrap :align-center="$vuetify.breakpoint.smAndUp"
      :align-content-center="$vuetify.breakpoint.smAndDown">
      <v-flex xs12 md4>
        <h3 class="primary--text">Usuários</h3>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 md4 text-xs-right>
        <v-text-field label="Pesquisar" v-model="filtro" color="secondary" append-icon="search"></v-text-field>
      </v-flex>
      <v-flex xs12 md1 text-right
        v-if="usuarioLogado.idtipousuario == perfilUsr.ID.administrador || usuarioLogado.idtipousuario == perfilUsr.ID.cliente">
        <v-tooltip bottom color="secondary">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" fab small dark depressed color="secondary" @click="go('CadastroUsuario')">
              <v-icon dark>add</v-icon>
            </v-btn>
          </template>
          <span>Adicionar um novo usuário</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
    <template v-for="grupo in usuariosAgrupados">
      <v-layout row wrap :key="grupo.letra">
        <v-flex xs12>
          <h1 class="primary--text">{{ grupo.letra }}</h1>
          <v-layout row wrap>
            <v-flex xs12 sm6 lg3 v-for="usr in grupo.usuarios" :key="usr.idUsuario">
              <v-card>
                <v-img :src="require('@/assets/img/default-user.png')" height="200px">
                  <v-container fill-height fluid>
                    <v-layout fill-height>
                      <v-flex xs12 align-end flexbox>
                        <v-chip :color="`${usr.ativo ? 'green' : 'red'} lighten-2`" class="white--text" disabled>
                          <v-avatar :color="`${usr.ativo ? 'green' : 'red'}`">
                            <v-icon color="white">person</v-icon>
                          </v-avatar>
                          {{ usr.ativo ? "Ativo" : "Inativo" }}
                        </v-chip>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-img>
                <v-card-text>
                  <div class="headline" :title="usr.nome">
                    {{ diminuiString(usr.nome, 23) }}
                  </div>
                  <span class="grey--text" :title="usr.email">{{
      diminuiString(usr.email, 39)
    }}</span>
                  <br />
                  <span class="grey--text">Tipo Usuário: {{ usr.tipoUsuario.nome }}</span>
                  <br />
                  <span class="grey--text">Empresa: {{ usr.empresa.nome }}</span>
                </v-card-text>
                <v-card-actions>
                  <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="
      editarUsuario(cipherComputed.encrypt(usr.idUsuario))
      ">
                        <v-icon color="primary">edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar Usuário</span>
                  </v-tooltip>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </template>
    <v-snackbar :timeout="3200" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`"
      v-model="snackbarModel.mostrar" bottom multi-line>
      {{ snackbarModel.text }}
      <v-btn dark text @click.native="snackbarModel.mostrar = false">Fechar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import cipher from "@/helpers/cipher.js";
import customFormat from "@/helpers/custom-format.js";
import jwt from "@/helpers/jwt";
import usuarioHelper from "@/helpers/usuario.js";
import perfil from "@/helpers/perfil.js";

export default {
  name: "Usuarios",
  data: () => ({
    lstUsuarios: [],
    filtro: "",
    snackbarModel: {
      mostrar: false,
      sucesso: false,
      text: "",
    }
  }),
  computed: {
    perfilUsr: function () {
      return perfil;
    },
    usuarioLogado: function () {
      return usuarioHelper.USUARIO_LOGADO();
    },
    usuariosAgrupados: function () {
      const self = this;
      var result = [];
      self.lstUsuarios
        .sort((a, b) => {
          return a.nome.localeCompare(b.nome);
        })
        .filter((usuario) => {
          return (
            usuario.nome &&
            customFormat
              .removerAcentos(usuario.nome)
              .includes(customFormat.removerAcentos(self.filtro))
          );
        })
        .reduce(function (res, value) {
          var primeiraLetra = value.nome.substring(0, 1).toUpperCase();
          if (!res[primeiraLetra]) {
            res[primeiraLetra] = {
              usuarios: [],
              letra: primeiraLetra,
            };
            result.push(res[primeiraLetra]);
          }
          res[primeiraLetra].usuarios.push(value);
          return res;
        }, {});
      return result;
    },
    getLista: function () {
      return this.userParam.listUsers
        .filter((obj) => {
          return obj.id != this.userParam.userRemetente.idUsuario && obj.ativo;
        })
        .sort((a, b) => {
          return a.nome.localeCompare(b.nome);
        });
    },
    cipherComputed: function () {
      return cipher;
    },
  },
  watch: {
  },
  mounted: function () {
    this.listarUsuarios();
    this.externalSnackbarMessage();
  },
  methods: {
    listarUsuarios: function () {
      const self = this;
      const item = jwt.decryptToken();
      if (item != null) {
        self.$store.commit("startLoading");

        self.$http
          .get(`${process.env.VUE_APP_URL_API}/usuario/busca/`, {
            headers: {
              Authorization:
                `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then(function (response) {
            self.lstUsuarios = response.data;
            self.$store.commit("endLoading");
          })
      } else {
        self.go("Login");
      }
    },
    editarUsuario: function (id) {
      const self = this;
      self.$router.push({ name: "CadastroUsuario", params: { id: id } });
    },
    externalSnackbarMessage: function () {
      const self = this;
      if (self.$route.params.snackbarMostrar) {
        self.snackbarModel.sucesso = self.$route.params.snackbarSucesso;
        self.snackbarModel.text = self.$route.params.snackbarText;
        self.snackbarModel.mostrar = self.$route.params.snackbarMostrar;
      }
    },
    diminuiString: function (param, numCaracteres) {
      if (param.length >= numCaracteres && param.indexOf(" ") < 0) {
        return param.slice(0, numCaracteres - 3) + "...";
      } else {
        return param;
      }
    },
    go: function (routeName, routeParams) {
      const self = this;
      if (!routeParams) {
        self.$router.push({ name: routeName });
      } else {
        self.$router.push({ name: routeName, params: routeParams });
      }
    },
  },
};
</script>
