<template>
    <v-container fluid fill-height>
        <v-layout justify-center>
            <v-flex xs12 sm8>
                <v-card class="elevation-12 default-border">
                    <v-toolbar color="secondary">
                        <v-toolbar-title>{{
                            this.$route.params.id
                                ? "Edição Conta Usuário " + cadastroModel.codigoConta
                                : "Cadastro Conta Usuário"
                        }}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form v-model="formularioCadastroValido" ref="formularioCadastroRef">
                            <v-container grid-list-lg>
                                <v-layout row wrap>
                                    <v-flex xs12 md6>
                                        <v-text-field v-model="precoFormatado" color="secondary"
                                            :rules="regras.branco" prepend-icon="mdi-domain" label="Limite Diário Brl"
                                            type="text" required v-money="money" ref="txtProcFormatado"></v-text-field>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn id="voltar" color="primary" class="grey--text text--darken-1"
                            :to="{ name: 'ContaUsuarios' }" text>Voltar
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn id="finalizar" color="secondary" class="default-border" depressed @click="finalizar()"
                            :disabled="disableBtnFinalizar || !formValid">Salvar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <v-snackbar :timeout="5000" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`" bottom multi-line
            v-model="snackbarModel.mostrar">
            {{ snackbarModel.text }}
            <v-btn text dark @click.native="
                            `${snackbarModel.sucesso
                                ? go('ContaUsuarios')
                                : (snackbarModel.mostrar = false)
                            }`
                            ">Fechar</v-btn>
        </v-snackbar>
    </v-container>
</template>


<script>
import cipher from "@/helpers/cipher.js";
import customFormat from "@/helpers/custom-format.js";
import jwt from "@/helpers/jwt";
import { VMoney } from 'v-money'

export default {
    name: "CadastroContaUsuarios",
    data: () => ({
        formularioCadastroValido: false,
        disableBtnFinalizar: false,
        precoFormatado: null,
        snackbarModel: {
            mostrar: false,
            sucesso: false,
            text: "",
        },
        cadastroModel: {
            idContaUsuario: null,
            codigoConta: null,
            codigoContaChavePixUniversal: null,
            codigoWallet: null,
            contaChavePixVip: null,
            dataInclusao: null,
            email: null,
            empresa: null,
            empresaCodigo: null,
            limiteDiarioBrl: null
        },
        regras: {
            branco: [(v) => !!v || "Você não pode deixar este campo em branco"]
        },
        money: {
            decimal: ',',
            thousands: '.',
            precision: 2,
            masked: false
        },
    }),
    directives: { money: VMoney },
    mounted: function () {
        this.buscar()
        this.externalSnackbarMessage()
    },
    watch: {
        precoFormatado: function (val) {
            const self = this;
            self.cadastroModel.limiteDiarioBrl = customFormat.removeMoneyFormat(val);
        },
    },
    computed: {
        formValid: function () {
            return this.formularioCadastroValido;
        },
        customFormatComputed: function () {
            return customFormat;
        },
        cipherComputed: function () {
            return cipher;
        }
    },
    methods: {
        buscar: function () {
            const self = this;
            const item = jwt.decryptToken();
            if (item != null) {
                if (
                    this.$route.params.id &&
                    cipher.decrypt(this.$route.params.id)
                ) {
                    self.$store.commit("startLoading");
                    self.$http
                        .get(
                            `${process.env.VUE_APP_URL_API}/conta-usuario/busca/` +
                            cipher.decrypt(this.$route.params.id),
                            {
                                headers: {
                                    Authorization:
                                        `Bearer ${localStorage.getItem("token")}`,
                                },
                            }
                        )
                        .then(function (response) {
                            self.cadastroModel = response.data[0];

                            self.$refs.txtProcFormatado.$el.getElementsByTagName(
                                "input"
                            )[0].value = ("R$ " + response.data[0].limiteDiarioBrl).replace(".", ",");

                            self.$store.commit("endLoading");
                        }).catch(function (error) {
                            self.$store.commit("endLoading");
                            self.snackbarModel.text = error.response.data.mensagem;
                            self.snackbarModel.sucesso = false;
                            self.snackbarModel.mostrar = true;
                            self.disableBtnFinalizar = false;
                            console.log(error);
                        });
                }
            }
        },
        finalizar: function () {
            const self = this;
            self.$store.commit("startLoading");
            if (self.$refs.formularioCadastroRef.validate()) {
                const item = jwt.decryptToken();
                if (item != null) {
                    if (self.$route.params.id) {
                        self.cadastroModel.idContaUsuario = cipher.decrypt(
                            self.$route.params.id
                        );
                        self.$http
                            .post(
                                `${process.env.VUE_APP_URL_API}/conta-usuario/edicao`,
                                self.cadastroModel,
                                {
                                    headers: {
                                        Authorization:
                                            `Bearer ${localStorage.getItem("token")}`,
                                    },
                                }
                            )
                            .then(function (response) {
                                self.$store.commit("endLoading");
                                const data = response.data;
                                if (data.sucesso) {
                                    self.disableBtnFinalizar = data.sucesso;
                                    self.go("ContaUsuarios", {
                                        snackbarText: data.mensagem,
                                        snackbarMostrar: true,
                                        snackbarSucesso: data.sucesso,
                                    });
                                } else {
                                    self.snackbarModel.text = data.mensagem;
                                    self.snackbarModel.mostrar = true;
                                    self.snackbarModel.sucesso = data.sucesso;
                                    self.disableBtnFinalizar = data.sucesso;
                                }
                            })
                            .catch(function (error) {
                                self.$store.commit("endLoading");
                                self.snackbarModel.text = error.response.data.mensagem;
                                self.snackbarModel.sucesso = false;
                                self.snackbarModel.mostrar = true;
                                self.disableBtnFinalizar = false;
                            });
                    }
                } else {
                    self.go("Login");
                }
            }
        },
        externalSnackbarMessage: function () {
            const self = this;
            if (self.$route.params.snackbarMostrar) {
                self.snackbarModel.sucesso = self.$route.params.snackbarSucesso;
                self.snackbarModel.text = self.$route.params.snackbarText;
                self.snackbarModel.mostrar = self.$route.params.snackbarMostrar;
            }
        },
        go: function (routeName, routeParams) {
            const self = this;
            if (!routeParams) {
                self.$router.push({ name: routeName });
            } else {
                self.$router.push({ name: routeName, params: routeParams });
            }
        },
    }
};
</script>