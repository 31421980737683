import perfil from '@/helpers/perfil.js';
import usuarioHelper from '@/helpers/usuario.js';

export default {
  Home: {
    acesso: true
  },
  Login: {
    acesso: true
  },
  ErroPaginaNaoEncontrada: {
    acesso: true
  },
  SideMenu: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario >= perfil.ID.administrador
  },
  RecuperarSenha: {
    acesso: true
  },
  EsqueciSenha: {
    acesso: true
  },
  Portal: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario >= perfil.ID.administrador
  },
  Usuarios: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  CadastroUsuario: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  Empresas: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador
  },
  CadastroEmpresa: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador
  },
  ContaHome: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  Pedidos: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  PedidoSaques: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  PedidosAdmin: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador
  },
  PedidoSaquesAdmin: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador
  },
  ContaUsuarios: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  ContaUsuariosAdmin: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador
  },
  CadastroContaUsuarios: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  CodigoEmpresas: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  CadastroCodigoEmpresa: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
  Contas: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador
  },
  CompraMoedaAdmin: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador
  },
  PainelPiloto: {
    acesso: usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.administrador || usuarioHelper.USUARIO_LOGADO().idtipousuario == perfil.ID.cliente
  },
}
