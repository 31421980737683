<template>
    <v-container>
        <v-layout align-center>
            <v-flex xs10>
                <h3 class="secondary--text">Conta</h3>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 md4 text-xs-right>
                <v-text-field label="Pesquisar" v-model="filtro" color="secondary" append-icon="search"></v-text-field>
            </v-flex>
            <!-- <v-flex xs1 text-xs-right>
                <v-tooltip color="secondary" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" slot="activator" dark small color="secondary" depressed
                            @click="abrirQrCodeModal">
                            <v-icon>mdi-qrcode</v-icon>
                        </v-btn>
                    </template>
<span>Adicionar Valor a Conta</span>
</v-tooltip>
</v-flex>
<v-flex xs1 text-xs-right>
    <v-tooltip color="secondary" bottom>
        <template v-slot:activator="{ on }">
                        <v-btn v-on="on" slot="activator" dark small color="secondary" depressed disabled>
                            <v-icon>add</v-icon>
                        </v-btn>
                    </template>
        <span>Adicionar uma nova Empresa</span>
    </v-tooltip>
</v-flex>
<v-flex xs1 text-xs-right>
    <v-tooltip color="secondary" bottom>
        <template v-slot:activator="{ on }">
                        <v-btn v-on="on" slot="activator" dark small color="secondary" depressed disabled>
                            <v-icon>add</v-icon>
                        </v-btn>
                    </template>
        <span>Adicionar uma nova Empresa</span>
    </v-tooltip>
</v-flex>-->
        </v-layout>
        <v-layout row wrap>
            <v-flex xs12 lg3>
                <v-card color="purple darken-2" class="white--text">
                    <v-card-title primary-title>
                        <div>
                            <div class="headline">Saldo</div>
                            <span class="subheading mr-2">{{ customFormatComputed.formatarReais(dashboard.sdpd)
                                }}</span>
                            <br />
                        </div>
                    </v-card-title>
                </v-card>
            </v-flex>
            <v-flex xs12 lg3>
                <v-card color="blue-grey darken-1" class="white--text">
                    <v-card-title primary-title>
                        <div>
                            <div class="headline">Saldo a Utilizar</div>
                            <span class="subheading mr-2">{{
                    customFormatComputed.formatarReais(dashboard.saldoUtilizado)
                }}</span>
                            <br />
                        </div>
                    </v-card-title>
                </v-card>
            </v-flex>
            <v-flex xs12 lg3>
                <v-card color="cyan darken-2" class="white--text">
                    <v-card-title primary-title>
                        <div>
                            <div class="headline">Número de Contas</div>
                            <span class="subheading mr-2">{{ dashboard.contas
                                }}</span>
                            <br />
                        </div>
                    </v-card-title>
                </v-card>
            </v-flex>
            <v-flex xs12 lg3>
                <v-card color="blue darken-2" class="white--text">
                    <v-card-title primary-title>
                        <div>
                            <div class="headline">Valor de Crédito</div>
                            <span>R$: </span>
                            <span class="subheading mr-2">{{ customFormatComputed.formatarReais(dashboard.valorCredito)
                                }}</span>
                            <br />
                        </div>
                    </v-card-title>
                </v-card>
            </v-flex>
        </v-layout>
        <v-layout justify-space-around row wrap>
            <v-flex xs12>
                <v-card class="default-border" flat text>
                    <v-data-table :headers="headers" :items="listaModelo" class="elevation-1 table-motoristas"
                        :search="filtro" :sort-by="['createdAt']" :sort-desc="[true]" :items-per-page="20"
                        :footer-props="{
                    itemsPerPageAllText: 'Todos',
                    itemsPerPageText: 'Itens por página',
                }">
                        <template v-slot:[`item.amount`]="{ item }">
                            {{ customFormatComputed.formatarReais(item.amount) }}
                        </template>
                        <template v-slot:[`item.numeroDocumento`]="{ item }">
                            {{ customFormatComputed.formatCPFCNPJNumber(item.numeroDocumento) }}
                        </template>
                        <template v-slot:[`item.type`]="{ item }">
                            {{ item.type == "debit" ? "Débito" : "Crédito" }}
                        </template>
                        <template v-slot:[`item.createdAt`]="{ item }">
                            {{
                    customFormatComputed.toLocaleDateTimeBrPtString(item.createdAt)
                }}
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
        <v-snackbar :timeout="3200" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`"
            v-model="snackbarModel.mostrar" bottom multi-line>
            {{ snackbarModel.text }}
            <v-btn dark text @click.native="snackbarModel.mostrar = false">Fechar</v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
import cipher from "@/helpers/cipher.js";
import customFormat from "@/helpers/custom-format.js";
import utils from "@/helpers/utils.js";

export default {
    name: "ContaHome",
    data: () => ({
        snackbarModel: {
            mostrar: false,
            sucesso: false,
            text: "",
        },
        dashboard: {
            sdpd: 0,
            saldoUtilizado: 0,
            contas: 0,
            valorCredito: 0
        },
        filtro: "",
        headers: [
            { text: "Data", value: "createdAt" },
            { text: "Tipo", value: "type" },
            { text: "Categoria", value: "category" },
            { text: "Nome", value: "name" },
            { text: "Documento", value: "numeroDocumento" },
            { text: "Descrição", value: "description" },
            { text: "Banco", value: "bankName" },
            { text: "Agência / Nº da Conta", value: "agenciaCC" },
            { text: "Valor", value: "amount" },
        ],
        listaModelo: []
    }),
    mounted: function () {
        this.buscar()
        this.buscarSaldo();
        this.externalSnackbarMessage()
    },
    computed: {
        customFormatComputed: function () {
            return customFormat;
        },
        cipherComputed: function () {
            return cipher;
        },
        utilsComputed: function () {
            return utils;
        }
    },
    methods: {
        buscar() {
            const self = this;
            self.$store.commit("startLoading");
            self.$http
                .get(
                    `${process.env.VUE_APP_URL_API}/conta/busca/extrato`,
                    {
                        headers: {
                            Authorization:
                                `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then(function (response) {
                    if (response.data.sucesso) {
                        response.data.rows.forEach(e => {
                            let nome = null;
                            let numeroDocumento = null;
                            let agenciaCC = null;

                            switch (e.category) {
                                case "TEV":
                                    nome = e.payer.name
                                    numeroDocumento = e.payer.documentNumber
                                    agenciaCC = e.transactionData.agency + "/" + e.transactionData.accountNumber
                                    break;
                                case "PIX":
                                    if (e.type != "credit") {
                                        nome = e.receiver.name
                                        numeroDocumento = e.receiver.documentNumber
                                        agenciaCC = e.transactionData.agency + "/" + e.transactionData.account
                                    } else {
                                        nome = e.payer.name
                                        numeroDocumento = e.payer.documentNumber
                                        agenciaCC = e.transactionData.agencyPayer + "/" + e.transactionData.accountPayer
                                    }
                                    break;
                            }

                            self.listaModelo.push({
                                numeroDocumento: numeroDocumento,
                                name: nome,
                                amount: e.amount,
                                type: e.type,
                                category: e.category,
                                description: e.transactionData.description,
                                agenciaCC: agenciaCC,
                                createdAt: e.createdAt,
                                bankName: e.transactionData.bankName
                            })
                        });
                    }
                    self.$store.commit("endLoading");
                }).catch(function (error) {
                    self.snackbarModel.text = error.response.data.mensagem;
                    self.snackbarModel.sucesso = false;
                    self.snackbarModel.mostrar = true;
                    self.disableBtnFinalizar = false;
                    self.$store.commit("endLoading");
                });
        },
        buscarSaldo() {
            const self = this;
            self.$http
                .get(
                    `${process.env.VUE_APP_URL_API}/portal/busca/saldo`,
                    {
                        headers: {
                            Authorization:
                                `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then(function (response) {
                    self.dashboard.sdpd = response.data;
                });
        },
        externalSnackbarMessage: function () {
            const self = this;
            if (self.$route.params.snackbarMostrar) {
                self.snackbarModel.sucesso = self.$route.params.snackbarSucesso;
                self.snackbarModel.text = self.$route.params.snackbarText;
                self.snackbarModel.mostrar = self.$route.params.snackbarMostrar;
            }
        },
        go: function (routeName, routeParams) {
            const self = this;
            if (!routeParams) {
                self.$router.push({ name: routeName });
            } else {
                self.$router.push({ name: routeName, params: routeParams });
            }
        }
    }
};
</script>