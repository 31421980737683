<template>
  <div>
    <v-toolbar color="secondary mb-5">
      <v-app-bar-nav-icon v-if="$route.meta.mostraSideMenu" @click="menuLateral = !menuLateral"></v-app-bar-nav-icon>
      <img height="50" @click="getCurrentUser() ? go('/portal') : ''" class="pointer"
        :src="require('@/assets/logo.svg')" />
      <v-spacer></v-spacer>
      <v-btn v-if="getCurrentUser() && this.$route.path !== '/login'" @click="abrirModalEmpresa()" color="black" text
        :disabled="usuarioLogado.idtipousuario != perfilUsr.ID.administrador"
        class="hidden-sm-and-down default-border">{{ usuarioLogado.nomeempresa }}</v-btn>
      <v-btn v-if="getCurrentUser() && this.$route.path !== '/login'" @click="abrirModalConta()" color="black" text
        :disabled="usuarioLogado.idtipousuario != perfilUsr.ID.administrador && usuarioLogado.idtipousuario != perfilUsr.ID.cliente"
        class="hidden-sm-and-down default-border">{{ usuarioLogado.numeroconta }}</v-btn>
      <v-menu v-if="!getCurrentUser() && this.$route.path !== '/login'" class="hidden-md-and-up" bottom left>
        <template v-slot:activator="{ on }"><v-btn v-on="on" dark icon>
            <v-icon>more_vert</v-icon>
          </v-btn></template>

        <v-list>
          <v-list-item @click="go('/login')">
            <v-list-item-title>Fazer Login</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <template>
        <v-dialog v-model="dialogEmpresa" persistent max-width="600px">
          <v-card>
            <v-form v-model="formularioCadastroValidoEmpresa" ref="formularioCadastroEmpresaRef">
              <v-card-title>
                <span class="text-h5">Selecione a Empresa</span>
              </v-card-title>
              <v-card-text>
                <v-select v-model="cadastroModel.empresa" color="secondary" :rules="regras.braco" :items="listaEmpresas"
                  item-text="nome" prepend-icon="account_balance" label="Empresas" required return-object></v-select>
                {{ notificao }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="closeEmpresa()">
                  Fechar
                </v-btn>
                <v-btn color="secondary" depressed :disabled="disableBtnEmpresaFinalizar || !formValidEmpresa"
                  @click="finalizarEmpresa">
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>
      <template>
        <v-dialog v-model="dialogConta" persistent max-width="600px">
          <v-card>
            <v-form v-model="formularioCadastroValidoConta" ref="formularioCadastroValidoContaRef">
              <v-card-title>
                <span class="text-h5">Selecione a Conta</span>
              </v-card-title>
              <v-card-text>
                <v-select v-model="cadastroModel.conta" color="secondary" :rules="regras.braco" :items="listaConta"
                  item-text="numeroConta" prepend-icon="account_balance" label="Contas" required persistent-hint
                  :hint="`Nome: ${cadastroModel.conta.nome}, Tipo Conta: ${cadastroModel.conta.tipoConta.nome}`"
                  return-object></v-select>
                {{ notificao }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="closeConta()">
                  Fechar
                </v-btn>
                <v-btn color="secondary" depressed :disabled="disableBtnContaFinalizar || !formValidConta"
                  @click="finalizarConta">
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>
    </v-toolbar>
    <SideMenu v-if="$route.meta.mostraSideMenu" v-model="menuLateral"></SideMenu>
  </div>
</template>

<script>
import usuarioHelper from "@/helpers/usuario.js";
import SideMenu from "@/components/compartilhados/SideMenu";
import customFormat from "@/helpers/custom-format.js";
import perfil from "@/helpers/perfil.js";
import jwt from "@/helpers/jwt";

export default {
  name: "Toolbar",
  data: () => {
    return {
      currentPath: "",
      menuLateral: false,
      dialogEmpresa: false,
      formularioCadastroValidoEmpresa: false,
      disableBtnEmpresaFinalizar: false,
      listaEmpresas: [],
      cadastroModel: { empresa: null, conta: { nome: 'Vazio', tipoConta: { nome: 'Vazio' } } },
      dialogConta: false,
      formularioCadastroValidoConta: false,
      disableBtnContaFinalizar: false,
      listaConta: [],
      notificao: "",
      regras: {
        branco: [(v) => !!v || "Você não pode deixar este campo em branco"],
      },
      getCurrentUser() {
        if (
          localStorage.getItem("token") == null &&
          !this.$route.meta.mostraSideMenu
        ) {
          this.menuLateral = false;
        }
        return localStorage.getItem("token");
      },
    };
  },
  components: {
    SideMenu,
  },
  computed: {
    usuarioLogado: function () {
      return usuarioHelper.USUARIO_LOGADO();
    },
    perfilUsr: function () {
      return perfil;
    },
    formValidEmpresa: function () {
      return this.formularioCadastroValidoEmpresa;
    },
    formValidConta: function () {
      return this.formularioCadastroValidoConta;
    },
  },
  methods: {
    abrirModalEmpresa() {
      const self = this;
      self.buscaEmpresas();
      self.dialogEmpresa = true;
    },
    closeEmpresa() {
      const self = this;

      self.notificao = "";
      self.cadastroModel.empresa = null;
      self.dialogEmpresa = false;
    },
    abrirModalConta() {
      const self = this;
      self.buscaConta();
      self.dialogConta = true;
    },
    closeConta() {
      const self = this;

      self.notificao = "";
      self.cadastroModel.conta = { nome: 'Vazio', tipoConta: { nome: 'Vazio' } },
        self.dialogConta = false;
    },
    finalizarEmpresa() {
      const self = this
      if (self.$refs.formularioCadastroEmpresaRef.validate()) {
        const item = jwt.decryptToken()
        if (item != null) {
          self.$http
            .get(
              `${process.env.VUE_APP_URL_API}/usuario/troca/empresa/` +
              self.cadastroModel.empresa.idEmpresa,
              {
                headers: {
                  Authorization:
                    `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then(function (response) {
              const data = response.data
              if (data.sucesso) {
                localStorage.setItem("token", data.token)
                self.closeEmpresa()
                self.$router.go()
              } else {
                self.notificao = data.mensagem;
              }
            })
            .catch(function (error) {
              console.log(error)
            })
        } else {
          self.go("Login")
        }
      }
    },
    finalizarConta() {
      const self = this
      if (self.$refs.formularioCadastroValidoContaRef.validate()) {
        const item = jwt.decryptToken()
        if (item != null) {
          self.$http
            .get(
              `${process.env.VUE_APP_URL_API}/usuario/troca/conta/` +
              self.cadastroModel.conta.idConta,
              {
                headers: {
                  Authorization:
                    `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then(function (response) {
              const data = response.data
              if (data.sucesso) {
                localStorage.setItem("token", data.token)
                self.closeConta()
                self.$router.go()
              } else {
                self.notificao = data.mensagem;
              }
            })
            .catch(function (error) {
              console.log(error)
            })
        } else {
          self.go("Login")
        }
      }
    },
    buscaEmpresas() {
      const self = this;
      self.$store.commit("startLoading");
      self.$http
        .get(
          `${process.env.VUE_APP_URL_API}/empresa/busca/ativo`,
          {
            headers: {
              Authorization:
                `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(function (response) {
          self.listaEmpresas = response.data;
          self.listaEmpresas.sort(customFormat.compareNome);
          self.$store.commit("endLoading");
        }).catch(function (error) {
          console.log(error)
          self.$store.commit("endLoading");
        })
    },
    buscaConta() {
      const self = this;
      self.$store.commit("startLoading");
      self.$http
        .get(
          `${process.env.VUE_APP_URL_API}/conta/busca/empresa/` + self.usuarioLogado.idempresa,
          {
            headers: {
              Authorization:
                `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(function (response) {
          self.listaConta = response.data;
          self.listaConta.sort(customFormat.compareNome);
          self.$store.commit("endLoading");
        }).catch(function (error) {
          console.log(error)
          self.$store.commit("endLoading");
        })
    },
    go: function (route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
