<template>
    <v-container>
        <v-layout align-center>
            <v-flex xs10>
                <h3 class="secondary--text">Painel Piloto</h3>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 md4 text-xs-right>
                <v-combobox v-model="pesquisaModel.tipoMoeda" :items="listatipoMoeda" label="Tipo Moeda"
                    @change="buscar" prepend-icon="mdi-currency-btc" item-text="nome" required
                    return-object></v-combobox>
            </v-flex>
            <v-flex xs12 md4 text-xs-right>
                <v-text-field label="Pesquisar" v-model="filtro" color="secondary" append-icon="search"></v-text-field>
            </v-flex>
        </v-layout>
        <v-layout row wrap>
            <v-flex xs12 lg3>
                <v-card color="purple darken-2" class="white--text">
                    <v-card-title primary-title>
                        <div>
                            <div class="headline">Valor BRL</div>
                            <span class="subheading mr-2">{{ customFormatComputed.formatarReais(dashboard.valorBRLTotal)
                                }}</span>
                            <br />
                        </div>
                    </v-card-title>
                </v-card>
            </v-flex>
            <v-flex xs12 lg3>
                <v-card color="blue-grey darken-1" class="white--text">
                    <v-card-title primary-title>
                        <div>
                            <div class="headline">Qtd. Moedas</div>
                            <span class="subheading mr-2">{{ dashboard.valorQtdMoedaTotal.toFixed(4)
                                }}</span>
                            <br />
                        </div>
                    </v-card-title>
                </v-card>
            </v-flex>
            <v-flex xs12 lg3>
                <v-card color="cyan darken-2" class="white--text">
                    <v-card-title primary-title>
                        <div>
                            <div class="headline">Valor Medio Moeda</div>
                            <span class="subheading mr-2">{{ dashboard.valorMedioMoedaTotal.toFixed(4)
                                }}</span>
                            <br />
                        </div>
                    </v-card-title>
                </v-card>
            </v-flex>
            <v-flex xs12 lg3>
                <v-card color="blue darken-2" class="white--text">
                    <v-card-title primary-title>
                        <div>
                            <div class="headline">Qdt. Pedidos Em Abertos</div>
                            <span class="subheading mr-2">{{ dashboard.qtdPedidosAbertosTotal
                                }}</span>
                            <br />
                        </div>
                    </v-card-title>
                </v-card>
            </v-flex>
        </v-layout>
        <v-layout justify-space-around row wrap>
            <v-flex xs12>
                <v-card class="default-border" flat text>
                    <v-container grid-list-lg>
                        <v-form v-model="formularioStatusOperacao" ref="formularioStatusOperacaoRef">
                            <v-layout row wrap>
                                <v-flex xs12 md2>
                                    <v-text-field v-model="cadastroModel.limiteSwapAuto" color="secondary"
                                        :rules="regras.branco" prepend-icon="mdi-currency-usd"
                                        label="Valor Limite Swap Automatico" type="number"
                                        hint="Regra: Acima desse valor todos os Swap`s serão manuais quando o Status Operação tiver como manual"
                                        required></v-text-field>
                                </v-flex>
                                <v-flex xs12 md2><v-combobox v-model="cadastroModel.tipoStatusOperacao"
                                        :items="listaTipoStatusOperacao" label="Status Operação"
                                        prepend-icon="account_balance" item-text="nome" required
                                        return-object></v-combobox></v-flex>
                                <v-flex xs12 md2>
                                    <v-btn id="salvar" color="secondary" depressed
                                        :disabled="disableBtnFinalizar || !formValid" @click="finalizar">Salvar</v-btn>
                                </v-flex>
                                <v-flex xs12 md4>
                                    <h1>{{ formattedTime }}</h1> Tempo para voltar para configuração 100% automatica
                                </v-flex>
                            </v-layout>
                        </v-form>
                    </v-container>
                </v-card>
            </v-flex>
        </v-layout>
        <v-layout justify-space-around row wrap>
            <v-flex xs12>
                <v-card class="default-border" flat text>
                    <v-data-table :headers="headers" :items="listaModelo" class="elevation-1 table-motoristas"
                        :search="filtro" :sort-by="['dataPagamento']" :sort-desc="[true]" :items-per-page="20"
                        :footer-props="{
                    itemsPerPageAllText: 'Todos',
                    itemsPerPageText: 'Itens por página',
                }">
                        <template v-slot:[`item.compraAutomatica`]="{ item }">
                            {{ item.compraAutomatica ? "Sim" : "Não" }}
                        </template>
                        <template v-slot:[`item.valorBRL`]="{ item }">
                            {{ customFormatComputed.formatarReais(item.valorBRL) }}
                        </template>
                        <template v-slot:[`item.dataPagamento`]="{ item }">
                            {{
                    customFormatComputed.toLocaleDateTimeBrPtString(item.dataPagamento)
                }}
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
        <v-snackbar :timeout="3200" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`"
            v-model="snackbarModel.mostrar" bottom multi-line>
            {{ snackbarModel.text }}
            <v-btn dark text @click.native="snackbarModel.mostrar = false">Fechar</v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
import cipher from "@/helpers/cipher.js";
import customFormat from "@/helpers/custom-format.js";

export default {
    name: "PainelPiloto",
    data: () => ({
        disableBtnFinalizar: false,
        formularioStatusOperacao: false,
        atulizarTela: false,
        time: 0,
        timeLimit: 15 * 60,
        interval: null,
        snackbarModel: {
            mostrar: false,
            sucesso: false,
            text: "",
        },
        dashboard: {
            valorBRLTotal: 0,
            valorMedioMoedaTotal: 0,
            valorQtdMoedaTotal: 0,
            qtdPedidosAbertosTotal: 0
        },
        pesquisaModel: { tipoMoeda: { idTipoMoeda: 1, nome: "USDT" } },
        cadastroModel: {
            limiteSwapAuto: 1000,
            tipoStatusOperacao: null
        },
        listaTipoStatusOperacao: [],
        filtro: "",
        headers: [
            { text: "Tipo Operação", value: "tipoStatusOperacao.nome" },
            { text: "Compra Automatica", value: "compraAutomatica" },
            { text: "Moeda", value: "tipoMoeda.nome" },
            { text: "Valor BRL", value: "valorBRL" },
            { text: "Qtd. Moedas", value: "valorMoeda" },
            { text: "Cotação da Moeda", value: "cotacaoMoeda" },
            { text: "Empresa", value: "empresa.apelido" },
            { text: "Data Pagamento", value: "dataPagamento" },
        ],
        listatipoMoeda: [
            { idTipoMoeda: 1, nome: "USDT" },
            { idTipoMoeda: 2, nome: "BRGX" }
        ],
        listaModelo: [],
        regras: {
            branco: [(v) => !!v || "Você não pode deixar este campo em branco"],
        }
    }),
    mounted: function () {
        this.buscar()
        this.buscarTiposStatusOperacao();
        this.startTimer()
        this.externalSnackbarMessage()
    },
    beforeDestroy() {
        // Limpar o intervalo quando o componente for destruído
        if (this.interval) {
            clearInterval(this.interval);
        }
    },
    computed: {
        formattedTime() {
            const minutes = Math.floor(this.time / 60);
            const seconds = this.time % 60;
            return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        },
        customFormatComputed: function () {
            return customFormat;
        },
        cipherComputed: function () {
            return cipher;
        },
        formValid: function () {
            return this.formularioStatusOperacao;
        }
    },
    methods: {
        buscarTiposStatusOperacao() {
            const self = this;

            self.listaTipoStatusOperacao.push({ idTipoStatusOperacao: 2, nome: "Automatico QRCode/Swap" })
            self.listaTipoStatusOperacao.push({ idTipoStatusOperacao: 3, nome: "Manual QRCode/Swap" })
            self.listaTipoStatusOperacao.push({ idTipoStatusOperacao: 4, nome: "Automatico QRCode" })
            self.listaTipoStatusOperacao.push({ idTipoStatusOperacao: 5, nome: "Manual QRCode" })
            self.listaTipoStatusOperacao.push({ idTipoStatusOperacao: 6, nome: "Automatico Swap" })
            self.listaTipoStatusOperacao.push({ idTipoStatusOperacao: 7, nome: "Manual Swap" })
        },
        startTimer() {
            const self = this;
            self.interval = setInterval(() => {
                if (self.time < self.timeLimit) {
                    self.time++;
                } else {

                    self.cadastroModel = {
                        limiteSwapAuto: 1000,
                        tipoStatusOperacao: { idTipoStatusOperacao: 2, nome: "Automatico QRCode/Swap" }
                    }
                    clearInterval(self.interval);
                }
            }, 1000);

        },
        finalizar() {
            const self = this;
            clearInterval(self.interval);
            self.time = 0;
            self.startTimer();
            self.$store.commit("startLoading");
            if (self.$refs.formularioStatusOperacaoRef.validate()) {
                self.$http
                    .post(
                        `${process.env.VUE_APP_URL_API}/empresa/status-operacao/atualiza`,
                        self.cadastroModel,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        const data = response.data;
                        console.log(data);
                        if (data.sucesso) {
                            self.snackbarModel.text = data.mensagem;
                            self.snackbarModel.mostrar = true;
                            self.snackbarModel.sucesso = data.sucesso;
                        } else {
                            self.snackbarModel.text = data.mensagem;
                            self.snackbarModel.mostrar = true;
                            self.snackbarModel.sucesso = data.sucesso;
                            self.disableBtnFinalizar = data.sucesso;
                        }
                        self.$store.commit("endLoading");
                    })
                    .catch(function (error) {
                        self.snackbarModel.text = error.response.data.mensagem;
                        self.snackbarModel.sucesso = false;
                        self.snackbarModel.mostrar = true;
                        self.disableBtnFinalizar = false;
                        self.$store.commit("endLoading");
                    });
            }
        },
        buscar() {
            const self = this;

            self.dashboard.valorBRLTotal = 0;
            self.dashboard.valorMedioMoedaTotal = 0;
            self.dashboard.valorQtdMoedaTotal = 0;
            self.dashboard.qtdPedidosAbertosTotal = 0;

            self.$store.commit("startLoading");
            self.$http
                .get(
                    `${process.env.VUE_APP_URL_API}/pedido/compras/${self.pesquisaModel.tipoMoeda.idTipoMoeda}/busca`,
                    {
                        headers: {
                            Authorization:
                                `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then(function (response) {
                    self.listaModelo = [];
                    let valorMedioMoedaTotalTmp = 0;
                    let qtdPedidosAbertosTotal = 0;
                    response.data.forEach(e => {
                        if (e.compraAutomatica) {
                            self.dashboard.valorBRLTotal = self.dashboard.valorBRLTotal + e.valorBRL
                            valorMedioMoedaTotalTmp = valorMedioMoedaTotalTmp + e.cotacaoMoeda
                            self.dashboard.valorQtdMoedaTotal = self.dashboard.valorQtdMoedaTotal + e.valorMoeda
                            qtdPedidosAbertosTotal++;
                        }

                        self.listaModelo.push(e)
                    });

                    if (response.data.length > 0) {
                        self.dashboard.valorMedioMoedaTotal = valorMedioMoedaTotalTmp / qtdPedidosAbertosTotal
                        self.dashboard.qtdPedidosAbertosTotal = qtdPedidosAbertosTotal
                    }
                    else {
                        self.dashboard.valorQtdMoedaTotal = 0
                        self.dashboard.valorBRLTotal = 0
                        self.dashboard.valorMedioMoedaTotal = 0
                        self.dashboard.qtdPedidosAbertosTotal = 0
                    }

                    self.$store.commit("endLoading");
                }).catch(function (error) {
                    self.snackbarModel.text = error.response.data.mensagem;
                    self.snackbarModel.sucesso = false;
                    self.snackbarModel.mostrar = true;
                    self.disableBtnFinalizar = false;
                    self.$store.commit("endLoading");
                });
        },
        externalSnackbarMessage: function () {
            const self = this;
            if (self.$route.params.snackbarMostrar) {
                self.snackbarModel.sucesso = self.$route.params.snackbarSucesso;
                self.snackbarModel.text = self.$route.params.snackbarText;
                self.snackbarModel.mostrar = self.$route.params.snackbarMostrar;
            }
        },
        go: function (routeName, routeParams) {
            const self = this;
            if (!routeParams) {
                self.$router.push({ name: routeName });
            } else {
                self.$router.push({ name: routeName, params: routeParams });
            }
        }
    }
};
</script>