<template>
    <v-container>
        <v-layout align-center>
            <v-flex xs10>
                <h3 class="secondary--text">Conta Usuário</h3>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 md4 text-xs-right>
                <v-text-field label="Pesquisar" v-model="filtro" color="secondary" append-icon="search"></v-text-field>
            </v-flex>
        </v-layout>
        <v-layout justify-space-around row wrap>
            <v-flex xs12>
                <v-card class="default-border" flat text>
                    <v-data-table :headers="headers" :items="listaModelo" class="elevation-1 table-motoristas"
                        :search="filtro" :sort-by="['dataInclusao']" :sort-desc="[true]" :items-per-page="20"
                        :footer-props="{
                    itemsPerPageAllText: 'Todos',
                    itemsPerPageText: 'Itens por página',
                }">
                        <template v-slot:[`item.codigoWallet`]="{ item }">
                            {{ item.codigoWallet ? item.codigoWallet : "Vazio" }}
                        </template>
                        <template v-slot:[`item.contaChavePixVip`]="{ item }">
                            {{ item.contaChavePixVip ? item.contaChavePixVip.chavePix : "Sem vinculo" }}
                        </template>
                        <template v-slot:[`item.limiteDiarioBrl`]="{ item }">
                            {{ customFormatComputed.formatarReais(item.limiteDiarioBrl) }}
                        </template>
                        <template v-slot:[`item.email`]="{ item }">
                            {{ item.email ? item.email : "Vazio" }}
                        </template>
                        <template v-slot:[`item.empresa`]="{ item }">
                            {{ item.empresa ? item.empresa.apelido : "Sem empresa" }}
                        </template>
                        <template v-slot:[`item.empresaCodigo`]="{ item }">
                            {{ item.empresaCodigo ? item.empresaCodigo.codigo : "Sem código" }}
                        </template>
                        <template v-slot:[`item.dataInclusao`]="{ item }">
                            {{
                    customFormatComputed.toLocaleDateTimeBrPtString(item.dataInclusao)
                }}
                        </template>
                        <template v-slot:[`item.controle`]="{ item }">
                            <v-layout justify-center>
                                <v-tooltip color="secondary" bottom>
                                    <template v-slot:activator="{ on }">
                                        <td>
                                            <v-btn small depressed fab v-on="on" color="secondary"
                                                class="ma-2 px-1 icon-edit" @click="go('Pedidos', {
                    id:
                        cipherComputed.encrypt(item.idContaUsuario)
                })">
                                                <v-icon style="font-size: 16px">mdi-cart-arrow-down</v-icon>
                                            </v-btn>
                                        </td>
                                    </template>
                                    <span>Ver pedidos da conta {{ item.codigoConta }}</span>
                                </v-tooltip>
                                <v-tooltip color="secondary" bottom>
                                    <template v-slot:activator="{ on }">
                                        <td>
                                            <v-btn small depressed fab v-on="on" color="secondary"
                                                :disabled="item.empresaCodigo == null || (item.empresaCodigo != null && item.contaChavePixVip != null)"
                                                class="ma-2 px-1 icon-edit" @click="criaVip(item)">
                                                <v-icon style="font-size: 16px">mdi-account-check</v-icon>
                                            </v-btn>
                                        </td>
                                    </template>
                                    <span>Adicionar VIP {{ item.codigoConta }}</span>
                                </v-tooltip>
                                <v-tooltip color="secondary" bottom>
                                    <template v-slot:activator="{ on }">
                                        <td>
                                            <v-btn small depressed fab v-on="on" color="secondary"
                                                class="ma-2 px-1 icon-edit" @click="go('CadastroContaUsuarios', {
                    id:
                        cipherComputed.encrypt(item.idContaUsuario)
                })">
                                                <v-icon style="font-size: 16px">edit</v-icon>
                                            </v-btn>
                                        </td>
                                    </template>
                                    <span>Editar {{ item.codigoConta }}</span>
                                </v-tooltip>
                            </v-layout>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
        <v-snackbar :timeout="3200" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`"
            v-model="snackbarModel.mostrar" bottom multi-line>
            {{ snackbarModel.text }}
            <v-btn dark text @click.native="snackbarModel.mostrar = false">Fechar</v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
import cipher from "@/helpers/cipher.js";
import customFormat from "@/helpers/custom-format.js";

export default {
    name: "ContaUsuarios",
    data: () => ({
        snackbarModel: {
            mostrar: false,
            sucesso: false,
            text: "",
        },
        filtro: "",
        headers: [
            { text: "Wallet", value: "codigoWallet" },
            { text: "Código Conta", value: "codigoConta" },
            { text: "Empresa Código", value: "empresaCodigo" },
            { text: "Empresa", value: "empresa" },
            { text: "Limite Diário Brl", value: "limiteDiarioBrl" },
            { text: "Código Chave Pix", value: "codigoContaChavePixUniversal" },
            { text: "Chave Pix Vip", value: "contaChavePixVip" },
            { text: "Email", value: "email" },
            { text: "Data Inclusão", value: "dataInclusao" },
            { text: "", value: "controle", sortable: false },
        ],
        regras: {
            branco: [(v) => !!v || "Você não pode deixar este campo em branco"]
        },
        listaModelo: []
    }),
    mounted: function () {
        this.buscar()
        this.externalSnackbarMessage()
    },
    computed: {
        customFormatComputed: function () {
            return customFormat;
        },
        cipherComputed: function () {
            return cipher;
        }
    },
    methods: {
        criaVip(item) {
            const self = this;
            self.$store.commit("startLoading");
            self.$http
                .post(
                    `${process.env.VUE_APP_URL_API}/conta-usuario/cadastra/vip`,
                    { idContaUsuario: item.idContaUsuario, codigoConta: item.codigoConta },
                    {
                        headers: {
                            Authorization:
                                `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then(function (response) {
                    self.$store.commit("endLoading");
                    const data = response.data;
                    if (data.sucesso) {
                        self.buscar()
                    } else {
                        self.snackbarModel.text = data.mensagem;
                        self.snackbarModel.mostrar = true;
                        self.snackbarModel.sucesso = data.sucesso;
                        self.disableBtnFinalizar = data.sucesso;
                    }
                })
                .catch(function (error) {
                    self.$store.commit("endLoading");
                    self.snackbarModel.text = error.response.data.mensagem;
                    self.snackbarModel.sucesso = false;
                    self.snackbarModel.mostrar = true;
                    self.disableBtnFinalizar = false;
                });
        },
        buscar() {
            const self = this;
            if (
                this.$route.params.id &&
                Number.isInteger(parseInt(cipher.decrypt(this.$route.params.id)))
            ) {
                self.$http
                    .get(
                        `${process.env.VUE_APP_URL_API}/conta-usuario/busca/` +
                        cipher.decrypt(this.$route.params.id),
                        {
                            headers: {
                                Authorization:
                                    `Bearer ${localStorage.getItem("token")}`,
                            },
                        }
                    )
                    .then(function (response) {
                        self.listaModelo = response.data;
                    });
            } else {
                self.$store.commit("startLoading");
                self.$http
                    .get(`${process.env.VUE_APP_URL_API}/conta-usuario/busca`, {
                        headers: {
                            Authorization:
                                `Bearer ${localStorage.getItem("token")}`,
                        },
                    })
                    .then(function (response) {
                        self.listaModelo = response.data;
                        self.$store.commit("endLoading");
                    }).catch(function (error) {
                        console.log(error.response.data)
                        self.$store.commit("endLoading")
                    });
            }
        },
        externalSnackbarMessage: function () {
            const self = this;
            if (self.$route.params.snackbarMostrar) {
                self.snackbarModel.sucesso = self.$route.params.snackbarSucesso;
                self.snackbarModel.text = self.$route.params.snackbarText;
                self.snackbarModel.mostrar = self.$route.params.snackbarMostrar;
            }
        },
        go: function (routeName, routeParams) {
            const self = this;
            if (!routeParams) {
                self.$router.push({ name: routeName });
            } else {
                self.$router.push({ name: routeName, params: routeParams });
            }
        },
    }
};
</script>