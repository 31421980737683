import Vue from "vue";
import Router from "vue-router";
import Controller from "./controller.js";
import EsqueciSenha from "@/components/autenticacao/EsqueciSenha";
import Login from "@/components/autenticacao/Login";
import RecuperarSenha from "@/components/autenticacao/RecuperarSenha";
import Portal from "@/components/portal/Portal";
import ErroPaginaNaoEncontrada from "@/components/paginas-erro/ErroPaginaNaoEncontrada";
import Usuarios from "@/components/usuarios/Usuarios";
import CadastroUsuario from "@/components/usuarios/CadastroUsuario";
import Empresas from "@/components/empresas/Empresas";
import CadastroEmpresa from "@/components/empresas/CadastroEmpresa";

import PedidosAdmin from "@/components/pedidos/PedidosAdmin";
import PedidoSaquesAdmin from "@/components/pedidos/PedidoSaquesAdmin";
import Pedidos from "@/components/pedidos/Pedidos";
import PedidoSaques from "@/components/pedidos/PedidoSaques";
import ContaHome from "@/components/conta-cartos/ContaHome";
import Contas from "@/components/conta-cartos/Contas";

import ContaUsuarios from "@/components/conta-usuario/ContaUsuarios";
import CadastroContaUsuarios from "@/components/conta-usuario/CadastroContaUsuarios";
import ContaUsuariosAdmin from "@/components/conta-usuario/ContaUsuariosAdmin";

import CodigoEmpresas from "@/components/codigo-empresa/CodigoEmpresas";
import CadastroCodigoEmpresa from "@/components/codigo-empresa/CadastroCodigoEmpresa";

import CompraMoedaAdmin from "@/components/pedidos/CompraMoedaAdmin";

import PainelPiloto from "@/components/pedidos/PainelPiloto";


Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { requiresAuth: false, mostraSideMenu: false }
    },
    {
      path: "/",
      name: "Portal",
      alias: "/portal",
      component: Portal,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/usuarios",
      name: "Usuarios",
      component: Usuarios,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/usuario/cadastro",
      name: "CadastroUsuario",
      component: CadastroUsuario,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/empresas",
      name: "Empresas",
      component: Empresas,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/empresa/cadastro",
      name: "CadastroEmpresa",
      component: CadastroEmpresa,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/empresa/conta",
      name: "ContaHome",
      component: ContaHome,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/pedidos",
      name: "Pedidos",
      component: Pedidos,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/pedidos/saques",
      name: "PedidoSaques",
      component: PedidoSaques,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/admin/pedidos",
      name: "PedidosAdmin",
      component: PedidosAdmin,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/admin/pedidos/saques",
      name: "PedidoSaquesAdmin",
      component: PedidoSaquesAdmin,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/empresa/codigo",
      name: "CodigoEmpresas",
      component: CodigoEmpresas,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/empresa/codigo/cadastro",
      name: "CadastroCodigoEmpresa",
      component: CadastroCodigoEmpresa,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/conta/usuario",
      name: "ContaUsuarios",
      component: ContaUsuarios,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/admin/conta/usuario",
      name: "ContaUsuariosAdmin",
      component: ContaUsuariosAdmin,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/conta/usuario/cadastro",
      name: "CadastroContaUsuarios",
      component: CadastroContaUsuarios,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/contas",
      name: "Contas",
      component: Contas,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/admin/compra",
      name: "CompraMoedaAdmin",
      component: CompraMoedaAdmin,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/painel-piloto",
      name: "PainelPiloto",
      component: PainelPiloto,
      meta: { requiresAuth: true, mostraSideMenu: true }
    },
    {
      path: "/RecuperarSenha/:token",
      name: "RecuperarSenha",
      meta: {
        title: "Recuperação de Senha",
        navbartitle: "Recuperação de Senha",
        requiresAuth: false
      },
      component: RecuperarSenha
    },
    {
      path: "/EsqueciSenha",
      name: "EsqueciSenha",
      meta: {
        title: "Recuperação de Senha",
        navbartitle: "Recuperação de Senha",
        requiresAuth: false,
        hideLogin: true
      },
      component: EsqueciSenha
    },
    {
      path: "*",
      name: "ErroPaginaNaoEncontrada",
      component: ErroPaginaNaoEncontrada
    },
  ],
  mode: "history"
});

router.beforeEach((to, from, next) => {
  Controller.auth(to, from, next);
  Controller.permissaoAcesso(to, from, next);

  document.title = `Cambiador Digital - ${to.meta.navbartitle ?? to.name}`;
});

export default router;
