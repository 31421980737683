<template>
  <v-container fluid fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm8>
        <v-card class="elevation-12 default-border">
          <v-toolbar color="secondary">
            <v-toolbar-title>{{
              this.$route.params.id
                ? "Edição Código de Parceiro " + cadastroModel.nome
                : "Cadastro Código de Parceiro"
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="formularioCadastroValido" ref="formularioCadastroRef">
              <v-container grid-list-lg>
                <v-layout row wrap>
                  <v-flex xs12 md6>
                    <v-text-field v-model="cadastroModel.nome" color="secondary" :rules="regras.branco"
                      prepend-icon="mdi-domain" label="Nome" type="text" required></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="cadastroModel.limite" color="secondary" :rules="regras.branco"
                      prepend-icon="mdi-car-speed-limiter" label="Limite Maximo" type="number" required
                      hint="Regra: Limite maximo de compra de moedas"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="cadastroModel.spread" color="secondary" :rules="regras.branco"
                      prepend-icon="mdi-cart-percent" label="Spread" type="number" required 
                      hint="Regra: 1.1 igual a 10%, 1.01 igual a 1%, -1.01 igual a -1%"
                      ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="cadastroModel.spreadAuto" color="secondary" :rules="regras.branco"
                      prepend-icon="mdi-cart-percent" label="Spread Automatico" type="number" required 
                      hint="Regra: 1.1 igual a 10%, 1.01 igual a 1%, não pode ser negativo."
                      ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="cadastroModel.limiteUso" color="secondary" :rules="regras.branco"
                      prepend-icon="mdi-account-key" label="Limite de Uso" type="number" required 
                      hint="Regra: Numero maximo de contas que podem usar esse cupom."
                      ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field ref="txtTaxaFormatado" v-model="taxaFormatado" color="secondary"
                      :rules="regras.branco" prepend-icon="mdi-currency-usd" label="Taxa de Saque" type="text" required
                      hint="Regra: Quando o usuário faz SWAP ou Solicita o Saque em BRGX/USDT é cobrado uma tarifa, esse valor será cobrado na moeda do saque."
                      v-money="money"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-combobox v-model="cadastroModel.usuarioResponsavel" :items="listaUsuario" label="Usuário"
                      prepend-icon="group" item-text="nome" required
                      return-object></v-combobox>
                  </v-flex>
                  <v-flex xs12 md2>
                    <v-switch :label="`${cadastroModel.ativo ? 'Ativo' : 'Inativo'}`" v-model="cadastroModel.ativo"
                      color="primary"></v-switch>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn id="voltar" color="primary" class="grey--text text--darken-1" :to="{ name: 'CodigoEmpresas' }"
              text>Voltar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn id="finalizar" color="secondary" class="default-border" depressed @click="finalizar()"
              :disabled="disableBtnFinalizar || !formValid">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar :timeout="5000" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`" bottom multi-line
      v-model="snackbarModel.mostrar">
      {{ snackbarModel.text }}
      <v-btn text dark @click.native="
              `${snackbarModel.sucesso
                ? go('CodigoEmpresas')
                : (snackbarModel.mostrar = false)
              }`
              ">Fechar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import customFormat from "@/helpers/custom-format.js";
import cipher from "@/helpers/cipher.js";
import jwt from "@/helpers/jwt";
import { VMoney } from 'v-money'

export default {
  name: "CadastroCodigoEmpresa",
  data: () => ({
    formularioCadastroValido: false,
    disableBtnFinalizar: false,
    disableBtnBuscar: false,
    taxaFormatado:null,
    cadastroModel: {
      nome: null,
      spread: null,
      spreadAuto: null,
      limite: null,
      limiteUso: null,
      usuarioResponsavel: null,
      ativo: true,
      taxaSaqueValor: null
    },
    listaUsuario: [],
    snackbarModel: {
      mostrar: false,
      text: "",
      sucesso: false,
    },
    regras: {
      branco: [(v) => !!v || "Você não pode deixar este campo em branco"]
    },
    money: {
      decimal: ',',
      thousands: '.',
      precision: 2,
      masked: false
    },
  }),
  directives: { money: VMoney },
  computed: {
    formValid: function () {
      return this.formularioCadastroValido;
    },
    customFormatComputed: function () {
      return customFormat;
    },
  },
  watch: {
    taxaFormatado: function (val) {
      this.cadastroModel.taxaSaqueValor = customFormat.removeMoneyFormat(val);
    },
  },
  mounted: function () {
    this.buscarUsuario();
    this.buscar();
  },
  methods: {
    buscarUsuario() {
      const self = this;

      const item = jwt.decryptToken();
      if (item != null) {
        self.$http
          .get(
            `${process.env.VUE_APP_URL_API}/usuario/tipo-usuario/busca/4`,
            {
              headers: {
                Authorization:
                  `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then(function (response) {
            self.listaUsuario = response.data;
          });
      }
      else {
        self.go("Login");
      }
    },
    buscar: function () {
      const self = this;
      const item = jwt.decryptToken();
      if (item != null) {
        if (
          this.$route.params.id &&
          cipher.decrypt(this.$route.params.id)
        ) {
          self.$store.commit("startLoading");
          self.$http
            .get(
              `${process.env.VUE_APP_URL_API}/empresa/codigo/busca/` +
              cipher.decrypt(this.$route.params.id),
              {
                headers: {
                  Authorization:
                    `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then(function (response) {
              self.cadastroModel = response.data[0];

              self.$refs.txtTaxaFormatado.$el.getElementsByTagName(
                "input"
              )[0].value = ("R$ " + response.data[0].taxaSaqueValor).replace(".", ",");

              self.$store.commit("endLoading");
            }).catch(function (error) {
              self.$store.commit("endLoading");
              self.snackbarModel.text = error.response.data.mensagem;
              self.snackbarModel.sucesso = false;
              self.snackbarModel.mostrar = true;
              self.disableBtnFinalizar = false;
              console.log(error);
            });
        }
      }
    },
    finalizar: function () {
      const self = this;
      self.$store.commit("startLoading");
      if (self.$refs.formularioCadastroRef.validate()) {
        const item = jwt.decryptToken();
        if (item != null) {
          if (!self.$route.params.id) {
            self.$http
              .post(
                `${process.env.VUE_APP_URL_API}/empresa/codigo/cadastro`,
                self.cadastroModel,
                {
                  headers: {
                    Authorization:
                      `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )
              .then(function (response) {
                self.$store.commit("endLoading");
                const data = response.data;
                if (data.sucesso) {
                  self.disableBtnFinalizar = data.sucesso;

                  self.go("CodigoEmpresas", {
                    snackbarText: data.mensagem,
                    snackbarMostrar: true,
                    snackbarSucesso: data.sucesso,
                  });

                } else {
                  self.snackbarModel.text = data.mensagem;
                  self.snackbarModel.mostrar = true;
                  self.snackbarModel.sucesso = data.sucesso;
                  self.disableBtnFinalizar = data.sucesso;
                }
              })
              .catch(function (error) {
                self.$store.commit("endLoading");
                self.snackbarModel.text = error.response.data.mensagem;
                self.snackbarModel.sucesso = false;
                self.snackbarModel.mostrar = true;
                self.disableBtnFinalizar = false;
              });
          } else {
            self.cadastroModel.idCartao = cipher.decrypt(
              self.$route.params.id
            );
            self.$http
              .post(
                `${process.env.VUE_APP_URL_API}/empresa/codigo/edicao`,
                self.cadastroModel,
                {
                  headers: {
                    Authorization:
                      `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )
              .then(function (response) {
                self.$store.commit("endLoading");
                const data = response.data;
                if (data.sucesso) {
                  self.disableBtnFinalizar = data.sucesso;
                  self.go("CodigoEmpresas", {
                    snackbarText: data.mensagem,
                    snackbarMostrar: true,
                    snackbarSucesso: data.sucesso,
                  });
                } else {
                  self.snackbarModel.text = data.mensagem;
                  self.snackbarModel.mostrar = true;
                  self.snackbarModel.sucesso = data.sucesso;
                  self.disableBtnFinalizar = data.sucesso;
                }
              })
              .catch(function (error) {
                self.$store.commit("endLoading");
                self.snackbarModel.text = error.response.data.mensagem;
                self.snackbarModel.sucesso = false;
                self.snackbarModel.mostrar = true;
                self.disableBtnFinalizar = false;
              });
          }
        } else {
          self.go("Login");
        }
      }
    },
    go: function (routeName, routeParams) {
      const self = this;
      if (this.$router.currentRoute.name != routeName) {
        if (!routeParams) {
          self.$router.push({ name: routeName });
        } else {
          self.$router.push({ name: routeName, params: routeParams });
        }
      }
    },
  },
};
</script>