<template>
    <v-container>
        <v-layout align-center>
            <v-flex xs10>
                <h3 class="secondary--text">Pedidos</h3>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 md4 text-xs-right>
                <v-text-field label="Pesquisar" v-model="filtro" color="secondary" append-icon="search"></v-text-field>
            </v-flex>
        </v-layout>
        <v-layout justify-space-around row wrap>
            <v-flex xs12>
                <v-card class="default-border" flat text>
                    <v-data-table :headers="headers" :items="listaModelo" class="elevation-1 table-motoristas"
                        :search="filtro" :sort-by="['dataInclusao']" :sort-desc="[true]" :items-per-page="20"
                        :footer-props="{
                    itemsPerPageAllText: 'Todos',
                    itemsPerPageText: 'Itens por página',
                }">
                        <template v-slot:[`item.numeroDocumento`]="{ item }">
                            {{ customFormatComputed.formatCPFCNPJNumber(item.numeroDocumento) }}
                        </template>
                        <template v-slot:[`item.valorBRL`]="{ item }">
                            {{ customFormatComputed.formatarReais(item.valorBRL) }}
                        </template>
                        <template v-slot:[`item.dataInclusao`]="{ item }">
                            {{
                    customFormatComputed.toLocaleDateTimeBrPtString(item.dataInclusao)
                }}
                        </template>
                        <template v-slot:[`item.controle`]="{ item }">
                            <v-layout justify-center>
                                <v-tooltip color="secondary" bottom>
                                    <template v-slot:activator="{ on }">
                                        <td>
                                            <v-btn small depressed fab v-on="on" color="secondary"
                                                class="ma-2 px-1 icon-edit" @click="abrirModalStatus(item)">
                                                <v-icon style="font-size: 16px">mdi-account-details-outline</v-icon>
                                            </v-btn>
                                        </td>
                                    </template>
                                    <span>Ver detalhes do pedido {{ item.idPedido }}</span>
                                </v-tooltip>
                            </v-layout>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
        <template>
            <v-dialog v-model="dialogStatus" persistent>
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Pedido - {{ cadastroModelo.idPedido }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container grid-list-lg>
                            <v-layout row wrap>
                                <v-flex xs12><v-divider></v-divider></v-flex>
                                <v-flex xs12><b>Wallet</b></v-flex>
                                <v-flex xs12><v-divider></v-divider></v-flex>
                                <v-flex xs12 md4>Código: {{ cadastroModelo.contaUsuario.codigoWallet }}</v-flex>
                                <v-flex xs12 md4>Conta: {{ cadastroModelo.contaUsuario.codigoConta }}</v-flex>
                                <v-flex xs12 md4>Limite: {{ cadastroModelo.contaUsuario.limite }}</v-flex>
                                <v-flex xs12 md4>Codigo Key Pix Universal: {{ cadastroModelo.contaUsuario.codigoContaChavePixUniversal }}</v-flex>
                                <v-flex xs12 md4>Empresa: {{ cadastroModelo.contaUsuario.empresa.apelido }}</v-flex>
                                <v-flex xs12 md4>Nome do código: {{ cadastroModelo.contaUsuario.empresa.empresaCodigo ?
                    cadastroModelo.contaUsuario.empresa.empresaCodigo.nome : 'Vazio' }}</v-flex>
                                <v-flex xs12 md4>Código: {{ cadastroModelo.contaUsuario.empresa.empresaCodigo ?
                    cadastroModelo.contaUsuario.empresa.empresaCodigo.codigo : 'Vazio' }}</v-flex>
                                <v-flex xs12 md4>Spread do código: {{ cadastroModelo.contaUsuario.empresa.empresaCodigo ?
                    cadastroModelo.contaUsuario.empresa.empresaCodigo.spread : 'Vazio' }}</v-flex>
                                <v-flex xs12 md4>Limite do código: {{ cadastroModelo.contaUsuario.empresa.empresaCodigo ?
                    cadastroModelo.contaUsuario.empresa.empresaCodigo.limite : 'Vazio' }}</v-flex>
                                <v-flex xs12><v-divider></v-divider></v-flex>
                                <v-flex xs12><b>Detalhes da Compra</b></v-flex>
                                <v-flex xs12><v-divider></v-divider></v-flex>
                                <v-flex xs12 md4>Valor Pago: {{
                    customFormatComputed.formatarReais(cadastroModelo.valorBRL) }}</v-flex>
                                <v-flex xs12 md4>Moeda: {{ cadastroModelo.valorMoeda }}</v-flex>
                                <v-flex xs12 md4>Bonus moeda: {{ cadastroModelo.valorBonusMoeda }}</v-flex>
                                <v-flex xs12 md4>Cotação moeda: {{ cadastroModelo.cotacaoMoeda }}</v-flex>
                                <v-flex xs12 md4>NumOp: {{ cadastroModelo.numOp }}</v-flex>
                                <v-flex xs12 md4>Nome Sacado: {{ cadastroModelo.nomeSacado }}</v-flex>
                                <v-flex xs12 md4>Numero do Documento: {{
                    customFormatComputed.formatCPFCNPJNumber(cadastroModelo.numeroDocumento) }}</v-flex>
                                <v-flex xs12 md4>Chave Pix: {{ cadastroModelo.chavePix }}</v-flex>
                                <v-flex xs12 md4>Dados Bancários: {{ cadastroModelo.dadosBancarios }}</v-flex>
                                <v-flex xs12 md4>Data Pagamento: {{
                    customFormatComputed.toLocaleDateTimeBrPtString(cadastroModelo.dataPagamento)
                }}</v-flex>
                                <v-flex xs12 md4>Data Inclusão: {{
                        customFormatComputed.toLocaleDateTimeBrPtString(cadastroModelo.dataInclusao) }}</v-flex>
                                <v-flex xs12><v-divider></v-divider></v-flex>
                                <v-flex xs12><b>Histórico do Pedido</b></v-flex>
                                <v-flex xs12><v-divider></v-divider></v-flex>
                                <v-flex xs12>
                                    <v-card class="default-border" flat text>
                                        <v-data-table :headers="headersHistorico"
                                            :items="cadastroModelo.listHistoricoPedido"
                                            class="elevation-1 table-motoristas" :search="filtro"
                                            :sort-by="['dataInclusao']" :sort-desc="[true]" :items-per-page="20"
                                            :footer-props="{
                    itemsPerPageAllText: 'Todos',
                    itemsPerPageText: 'Itens por página',
                }">
                                            <template v-slot:[`item.dataInclusao`]="{ item }">
                                                {{
                    customFormatComputed.toLocaleDateTimeBrPtString(item.dataInclusao)
                                                }}
                                            </template>

                                        </v-data-table>
                                    </v-card>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="closeStatus">
                            Fechar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
        <v-snackbar :timeout="3200" :color="`${snackbarModel.sucesso ? 'success' : 'error'}`"
            v-model="snackbarModel.mostrar" bottom multi-line>
            {{ snackbarModel.text }}
            <v-btn dark text @click.native="snackbarModel.mostrar = false">Fechar</v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
import cipher from "@/helpers/cipher.js";
import customFormat from "@/helpers/custom-format.js";

export default {
    name: "Pedidos",
    data: () => ({
        dialogStatus: false,
        snackbarModel: {
            mostrar: false,
            sucesso: false,
            text: "",
        },
        filtro: "",
        headers: [
            { text: "ID", value: "idPedido" },
            { text: "Código", value: "contaUsuario.codigoConta" },
            { text: "Nome", value: "nomeSacado" },
            { text: "CPF/CNPJ", value: "numeroDocumento" },
            { text: "Status Pedido", value: "tipoStatusPedido.nome" },
            { text: "BRL", value: "valorBRL" },
            { text: "Tipo Moeda", value: "tipoMoeda.nome" },
            { text: "Qtd Moeda", value: "valorMoeda" },
            { text: "Bônus Moeda", value: "valorBonusMoeda" },
            { text: "Cotação Moeda", value: "cotacaoMoeda" },
            { text: "Data Inclusão", value: "dataInclusao" },
            { text: "", value: "controle", sortable: false },
        ],
        headersHistorico: [
            { text: "Status Pedido", value: "tipoStatusPedido.nome" },
            { text: "Observação", value: "observacao" },
            { text: "Data Inclusão", value: "dataInclusao" },
        ],
        cadastroModelo: {
            contaUsuario: {
                codigoWallet: null,
                codigoConta: null,
                codigoContaChavePixUniversal: null,
                limite: 0,
                empresa: {
                    apelido: null,
                },
                empresaCodigo: {
                    nome: null,
                    codigo: null,
                    spread: 0,
                    limite: 0
                },
            },
            tipoMoeda: {
                nome: null
            },
            tipoStatusPedido: {
                nome: null
            },
            valorBRL: 0,
            valorMoeda: 0,
            valorBonusMoeda: 0,
            cotacaoMoeda: 0,
            numOp: null,
            chavePix: null,
            nomeSacado: null,
            numeroDocumento: null,
            dadosBancarios: null,
            dataPagamento: null,
            dataInclusao: null,
            listHistoricoPedido: []
        },
        listaModelo: []
    }),
    mounted: function () {
        this.buscar()
        this.externalSnackbarMessage()
    },
    computed: {
        customFormatComputed: function () {
            return customFormat;
        },
        cipherComputed: function () {
            return cipher;
        }
    },
    methods: {
        abrirModalStatus(item) {
            const self = this;
            self.cadastroModelo = item

            self.dialogStatus = true;
        },
        closeStatus() {
            const self = this;
            self.cadastroModelo = {
                contaUsuario: {
                    codigoWallet: null,
                    codigoConta: null,
                    codigoContaChavePixUniversal: null,
                    limite: 0,
                    empresa: {
                        apelido: null,
                    },
                    empresaCodigo: {
                        nome: null,
                        codigo: null,
                        spread: 0,
                        limite: 0
                    },
                },
                tipoMoeda: {
                    nome: null
                },
                tipoStatusPedido: {
                    nome: null
                },
                valorBRL: 0,
                valorMoeda: 0,
                valorBonusMoeda: 0,
                cotacaoMoeda: 0,
                numOp: null,
                nomeSacado: null,
                numeroDocumento: null,
                chavePix: null,
                dadosBancarios: null,
                listHistoricoPedido: [],
                dataPagamento: null,
                dataInclusao: null
            };

            self.dialogStatus = false;
        },
        buscar: function () {
            const self = this;

            if (
                this.$route.params.id &&
                Number.isInteger(parseInt(cipher.decrypt(this.$route.params.id)))
            ) {
                self.$store.commit("startLoading");
                self.$http
                    .get(
                        `${process.env.VUE_APP_URL_API}/pedido/conta-usuario/busca/` +
                        cipher.decrypt(this.$route.params.id),
                        {
                            headers: {
                                Authorization:
                                    `Bearer ${localStorage.getItem("token")}`,
                            },
                        }
                    )
                    .then(function (response) {
                        self.listaModelo = response.data;
                        self.$store.commit("endLoading");
                    }).catch(function (error) {
                        console.log(error.response.data)
                        self.$store.commit("endLoading")
                    });
            } else {
                self.$store.commit("startLoading");
                self.$http
                    .get(`${process.env.VUE_APP_URL_API}/pedido/busca`, {
                        headers: {
                            Authorization:
                                `Bearer ${localStorage.getItem("token")}`,
                        },
                    })
                    .then(function (response) {
                        self.listaModelo = response.data;
                        self.$store.commit("endLoading");
                    }).catch(function (error) {
                        console.log(error.response.data)
                        self.$store.commit("endLoading")
                    });
            }
        },
        externalSnackbarMessage: function () {
            const self = this;
            if (self.$route.params.snackbarMostrar) {
                self.snackbarModel.sucesso = self.$route.params.snackbarSucesso;
                self.snackbarModel.text = self.$route.params.snackbarText;
                self.snackbarModel.mostrar = self.$route.params.snackbarMostrar;
            }
        },
        go: function (routeName, routeParams) {
            const self = this;
            if (!routeParams) {
                self.$router.push({ name: routeName });
            } else {
                self.$router.push({ name: routeName, params: routeParams });
            }
        }
    }
};
</script>